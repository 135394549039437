<template>
  <div class="h-[calc(100vh)]">
    <div v-if="allConversations.length" class="flex mb-8 h-full">
      <section class="w-full md:w-80 h-full">
        <Tab @changeTab="changeTab" :numberOfTabs="tabs" :currentTab="tabNumber" :borderColor="'border-brand-primary'" hidden>
          <div v-if="tabNumber == 1" class="rounded-lg" id="general" role="tabpanel" aria-labelledby="general-tab">
            <div :className="'mb-2 w-full'" v-for="item in allConversations" :key="item">
              <div
                @click.stop="setSelectedConversation(item.uuid)"
                class="flex p-4 border-b border-gray-300 hover:rounded hover:bg-brand-primary cursor-pointer hover:text-white"
              >
                <img class="w-10 h-10 object-cover mr-4 rounded-full" :src="item.profile_url" alt="profileImage" />
                <div class="grow">
                  <p class="text-sm text-brand-black">{{ item.name }}</p>
                  <p v-if="item?.last_message?.type === 'text'" class="text-xs text-brand-black text-ellipsis overflow-hidden h-4">
                    {{ item?.last_message?.content }}
                  </p>
                  <p v-if="item?.last_message?.type === 'image'" class="flex items-center text-xs text-brand-black h-6">
                    <ChatImageSvg /> <span class="ml-1">Photo</span>
                  </p>
                  <p v-if="item?.last_message?.type === 'file'" class="flex items-center text-xs text-brand-black h-6">
                    <ChatFileSvg /> <span class="ml-1">Document</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </section>
      <section :class="[selectedConversation ? '' : 'hidden', 'fixed md:relative md:block grow w-full conversation bg-white ']">
        <ChatRoom v-if="selectedConversation" :conversationUuid="selectedConversation" @closeChat="selectedConversation = null" />
        <div v-else class="flex justify-center items-center flex-col h-full">
          <img src="../assets/images/image.png" class="w-1/2 object-cover" alt="" />
          <div class="text-center">
            <p class="text-3xl font-cooper font-bold text-brand-original">Conversation</p>
            <p class="text-base font-medium">Begin conversation with candidate</p>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <p class="p-6">You currently do not have a conversation.</p>
    </div>
  </div>
</template>

<script setup>
import ChatFileSvg from '@/assets/icons/chat-file.svg?inline';
import ChatImageSvg from '@/assets/icons/chat-image.svg?inline';
import { ref, onMounted, computed } from 'vue';
import Tab from '@/components/Tab.vue';
import ChatRoom from '@/components/ChatRoom.vue';
import { useStore } from 'vuex';

const tabNumber = ref(1);
const container = ref(null);
const store = useStore();
const selectedConversation = ref(null);
const tabs = ref([
  {
    name: 'General',
  },
  // {
  //   name: 'Archive',
  // },
]);
const changeTab = (value) => {
  tabNumber.value = value;
};
const scrollToBottom = () => {
  let el = container.value;
  el.scrollIntoView(false, {
    behavior: 'smooth',
    block: 'end',
  });
};

const allConversations = computed(() => {
  return store.getters['conversation/getSortedConversations'];
});

const setSelectedConversation = (uuid) => {
  selectedConversation.value = uuid;
};
</script>

<style>
.conversation {
  height: calc(100vh - 60px);
}
</style>
